import React from "react";

const BlogList = [
  {
    tag: "New Release",
    heading:
      "7 Techniques for Managing Changing Data Types in Semi-Structured Data Ingestion",
    content:
      "Polymorphic keys in semi-structured data can complicate storage and retrieval. This blog explores seven approaches to manage these dynamic fields, including schema enforcement, dynamic typing, and data type promotion, offering practical strategies for data engineers facing evolving data challenges.",
    link: "https://datazip.io/blog/handling-changing-data-type-during-semi-structured-data-ingestion/",
  },
  {
    tag: "New Release",
    heading:
      "How to Flatten and Query Arrays in Semi-Structured Nested JSON for Effective Data Extraction",
    content:
      "Struggling with querying arrays in semi-structured nested JSON data? This blog addresses the common pain points of flattening these arrays for effective querying. Discover various strategies to simplify data extraction, ensuring you can efficiently access and analyze your insights.",
    link: "https://datazip.io/blog/flatten-array",
  },
];

const FlatbreadBlogList = () => {
  return (
    <div className="olake-product-blog-container">
      <div className="olake-product-blog-main-heading">
        Read more from our blogs
      </div>
      <div className="olake-product-blog-list">
        {BlogList.map((blog) => {
          return (
            <div className="olake-product-blog">
              <div className="olake-product-blog-tag">{blog.tag}</div>
              <div className="olake-product-blog-heading">{blog.heading}</div>
              <div className="olake-product-blog-subheading">
                {blog.content}
              </div>
              <a
                href={blog.link}
                target="_blank"
                rel="noopener noreferrer"
                className="olake-product-blog-link"
              >
                Read more
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FlatbreadBlogList;
