import React from 'react';

const LayoutFull = ({ children }) => (
  <>
    <main className=" site-full">
      {children}
    </main>
  </>
);

export default LayoutFull;  