import { logoutAuth, setAuthToken } from "../redux/actions/authActions/actions";
import Request from "../utils/Request";

export const login =
  ({ email, password }, config) =>
  async (dispatch, getState) => {
    try {
      let response = await Request({
        url: "/auth/login",
        method: "POST",
        data: {
          email,
          password,
        },
        ...config,
      });
      const { data, status } = response;
      if (status === 200 && data?.access_token) {
        dispatch(setAuthToken());
        return true;
      } else {
        return data;
      }
    } catch (error) {
      console.log("my error is", error);
    }
  };

export const logout =
  (config) =>
  async (dispatch, getState) => {
    try {
      let response = await Request({
        url: "/auth/logout",
        method: "POST",
        ...config,
      });
      const { data, status } = response;
      if (status === 200) {
        dispatch(logoutAuth());
        return true;
      } else {
        return data;
      }
    } catch (error) {
      console.log("my error is", error);
    }
  };

export const signup =
  (
    { fullname: name, email, password, phone: phone_number, referral },
    config
  ) =>
  async (dispatch, getState) => {
    try {
      let response = await Request({
        url: "/auth/register",
        method: "POST",
        data: {
          name,
          email,
          password,
          phone_number,
          referral,
        },
        ...config,
      });
      const { data, status } = response;
      if (status === 201) {
        return true;
      } else {
        return data.error;
      }
    } catch (error) {
      console.log("my error is", error);
    }
  };

export const otpVerify =
  ({ email, otp }, config) =>
  async (dispatch, getState) => {
    try {
      let response = await Request({
        url: "/auth/register/verify",
        method: "POST",
        data: {
          email,
          code: otp,
        },
        ...config,
      });
      const { data, status } = response;
      if (status === 200) {
        return true;
      } else {
        return data.error;
      }
    } catch (error) {
      console.log("my error is", error);
    }
  };

export const ForgetPasswordInit =
  ({ email }, config) =>
  async (dispatch, getState) => {
    try {
      let response = await Request({
        url: "/auth/forgot/init",
        method: "POST",
        data: {
          email,
        },
        ...config,
      });
      const { data, status } = response;
      if (status === 200) {
        return true;
      } else {
        return data;
      }
    } catch (error) {
      console.log("my error is", error);
      return error;
    }
  };
export const ForgetPasswordConfirm =
  ({ email, code, password }, config) =>
  async (dispatch, getState) => {
    try {
      let response = await Request({
        url: "/auth/forgot/confirm",
        method: "POST",
        data: {
          email,
          code,
          new_password: password,
        },
        ...config,
      });
      const { data, status } = response;
      if (status === 200) {
        return true;
      } else {
        return data;
      }
    } catch (error) {
      console.log("my error is", error);
      return error;
    }
  };
