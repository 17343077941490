import React, { useEffect, useRef } from "react";
import ScrollReveal from "../../utils/ScrollReveal";

const features = [
  {
    heading: "Schema Changes break ETL Pipelines",
    subheading:
      "Adding/removing keys in source JSON (semi-structured) data might break downstream ETL pipeline logic & requires data reloading. ",
    image: require("../../assets/images/flatbread-product-1.svg"),
  },
  {
    heading: "Polymorphic Data (Changing data-types for same key)",
    subheading:
      "Different key data types across multiple records break the ETL & require a rewrite of ETL logic to handle, leading to constant maintenance. Example : Key changes from String to JSON object type.",
    image: require("../../assets/images/flatbread-product-2.svg"),
  },
  {
    heading: "Arrays are difficult to parse and query",
    subheading: "Extracting data from Array/stringified arrays requires parsing into an Array type (if the warehouse supports it) and complex transformation logic if the data is not flattened/exploded. This also requires a huge memory while querying leading to higher costs and latency.",
    image: require("../../assets/images/flatbread-product-3.svg"),
  },
  {
    heading: "Nested JSON requires Flattening to query",
    subheading:
      "Parsing semi-structured JSON data poses challenges due to its nested structures and variable schema, complicating data flattening and leading to inefficient queries.",
    image: require("../../assets/images/flatbread-product-4.svg"),
  },
  {
    heading: "Requirement of Data Quality Monitoring",
    subheading:
      "Schema changes, data-type changes or parsing failures requires monitoring & alerting to avoid long downtimes/missing data.",
    image: require("../../assets/images/flatbread-product-5.svg"),
  }
];

const FlatBreadFeatures = () => {
  const childRef = useRef();

  useEffect(() => {
    childRef.current.init();
  }, []);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <div className="flatbread-product-feature-box">
          <div className="flatbread-product-feature-heading reveal-from-top">
            Solve your nested JSON challenges with{" "}
            <br />
            <span className="flatbread-product-feature-heading-type1">
              Flatbread
            </span>{" "}
          </div>
          <div className="flatbread-product-feature-list reveal-from-bottom">
            {features.map((feature) => {
              return (
                <div className="flatbread-product-feature">
                  <img
                    src={feature.image}
                    alt={feature.heading}
                    className="flatbread-product-feature-image"
                  />
                  <div className="flatbread-product-feature-heading-box">
                    <div className="flatbread-product-feature-inner-heading">
                      {feature.heading}
                    </div>
                    <div className="flatbread-product-feature-subheading">
                      {feature.subheading}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    />
  );
};

export default FlatBreadFeatures;
