import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { outerLink } from "../../utils/functions";

const HeaderStrip = ({
  backgroundColor,
  textColor,
  linkColor,
  message,
  linkText,
  linkUrl,
  emoji,
}) => {
  const history = useHistory();

  // Determine how to handle the link click based on the URL
  const handleClick = () => {
    const isExternalLink = linkUrl.startsWith("http");
    // If the link is internal, use history.push to navigate
    if (!isExternalLink) {
      history.push(linkUrl);
    } else {
      // If the link is external
      outerLink(linkUrl);
    }
  };

  return (
    <div className="header-strip" style={{ backgroundColor, color: textColor }}>
      {emoji && (
        <span role="img" aria-label="Celebration for Product Launch">
          {emoji}
        </span>
      )}{" "}
      {message && <>{message} </>}
      <span
        onClick={handleClick}
        className="link-btn"
        style={{ color: linkColor }}
      >
        {linkText}
      </span>{" "}
      {emoji && (
        <span role="img" aria-label="Celebration for Product Launch">
          {emoji}
        </span>
      )}
    </div>
  );
};

// PropTypes for type checking the props
HeaderStrip.propTypes = {
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  linkColor: PropTypes.string,
  message: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  emoji: PropTypes.string,
};

// Default props for the HeaderStrip component
HeaderStrip.defaultProps = {
  backgroundColor: "#000",
  textColor: "#fff",
  linkColor: "#fff",
  emoji: "🎉",
};

export default HeaderStrip;
