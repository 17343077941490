import { LOGOUT, SETAUTHTOKEN } from "./actionType";

export const setAuthToken = () => (dispatch, getState) => {
  dispatch({
    type: SETAUTHTOKEN,
  });
};

export const logoutAuth = () => (dispatch, getState) => {
  dispatch({
    type: LOGOUT,
  });
};
