import {
  UPDATECARDS,
  UPDATEPLANDETAILS,
} from "../actions/paymentActions/actionType";

const initialState = {
  saved_cards: null,
  planDetails: null,
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATECARDS:
      return {
        ...state,
        saved_cards: [...action?.payload],
      };
    case UPDATEPLANDETAILS:
      return {
        ...state,
        planDetails: { ...action?.payload },
      };
    default:
      return state;
  }
};

export default paymentReducer;
