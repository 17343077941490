import React, { useEffect, useRef } from "react";
import ScrollReveal from "../../utils/ScrollReveal";

const FlatbreadFunctionality = () => {
  const childRef = useRef();

  useEffect(() => {
    childRef.current.init();
  }, []);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <div className="flatbread-product-function-box">
          <div className="flatbread-product-function-heading">
            Flatbread is a data engineering tool that simplifies<br/> the handling of
            complex JSON data pipelines.
          </div>
          <div className="flatbread-product-function-main">
            <div className="flatbread-product-function-main-heading">
              Functionality
            </div>
            <div className="flatbread-product-function-main-subheading">
              <div className="box-1">
                Normalises{" "}
                <span className="type-1">complex JSON into SQL-ready tables</span>{" "}
                for analytics handling changing JSON nested structures, ensuring
                downstream processes remain robust.{" "}
              </div>{" "}
              <div className="box-2">
                It automates parsing, extraction, transformation, and
                consistency checks without coding,{" "}
                <span className="type-1">
                  {" "}
                  providing reliable relational data
                </span>{" "}
                for analysis.
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default FlatbreadFunctionality;
