import {
  SETLOADING,
  SETUSER,
  UPDATEAPITOKENS,
  UPDATEDETAILS,
  UPDATEUSER,
} from "../actions/uiActions/actionType";

const initialState = {
  isLoading: false,
  activeApiTokens: [],
  user: null,
  deployDetails: null,
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETLOADING:
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    case UPDATEAPITOKENS:
      return {
        ...state,
        activeApiTokens: action.payload,
      };

    case UPDATEDETAILS:
      return {
        ...state,
        deployDetails: {
          ...state.deployDetails,
          ...action.payload,
        },
      };
    case SETUSER:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };

    case UPDATEUSER:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };

    default:
      return { ...state };
  }
};

export default uiReducer;
