import toast from "react-hot-toast";
import { setAuthToken } from "../redux/actions/authActions/actions";
import { setUser, updateDetails } from "../redux/actions/uiActions/actions";
import Request from "../utils/Request";

export const getDashboardData = (config) => async (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await Request({
        url: "/user/client",
        method: "GET",
        ...config,
      });
      if (response.status === 200 && response.data) {
        dispatch(updateDetails(response?.data.clients?.[0] || null));
        return resolve(response.data);
      }
      return resolve(response);
    } catch (error) {
      console.log(error);
      return reject(false);
    }
  });
};

export const getUserData = (config) => async (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await Request({
        url: "/user",
        method: "GET",
        ...config,
      });
      if (response?.status === 200 && response?.data) {
        dispatch(setUser(response?.data));
        dispatch(setAuthToken());
        return resolve(response.data);
      }
      if (response?.status === 401) {
        return resolve(false);
      }
      return resolve(response);
    } catch (error) {
      console.log(error);
      return reject(error);
    }
  });
};

export const submitDeployDetails =
  (formValues, config) => async (dispatch, getState) => {
    try {
      let response = await Request({
        url: "/deploy",
        method: "POST",
        data: {
          client_details: formValues,
          cloud_provider: "aws",
        },
        ...config,
      });
      if (response.status === 201 || response.status === 200) {
        return response.data;
      }
      toast.error(response.data);
      return null;
    } catch (error) {
      console.log(error);
      toast.error(error.response.data);
      return null;
    }
  };
