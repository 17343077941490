import { lazy } from "react";
import LayoutDashboard from "../layouts/LayoutDashboard";
import LayoutDefault from "../layouts/LayoutDefault";
import LayoutFull from "../layouts/LayoutFull";
import OlakeHome from "../views/Olake/Home";
import OlakeProduct from "../views/Olake/Product";
import NewLayout from "../layouts/NewLayout";
import FlatBreadProduct from "../views/FlatBread/Product";

const Signup = lazy(() => import("../views/Signup"));
const Login = lazy(() => import("../views/Login"));
const Dashboard = lazy(() => import("../views/Dashboard"));
const Pricing = lazy(() => import("../views/Pricing"));
const Product = lazy(() => import("../views/Product"));
const RevenueOperations = lazy(() => import("../views/RevenueOperations"));
const DataAnalyst = lazy(() => import("../views/DataAnalyst"));
const Operations = lazy(() => import("../views/Operations"));
const Finance = lazy(() => import("../views/Finance"));
const EmbedAnalytics = lazy(() => import("../views/EmbedAnalytics"));
const Home = lazy(() => import("../views/Home"));
const PrivacyPolicy = lazy(() => import("../views/PrivacyPolicy"));
const PrivacyPolicyCustomer = lazy(() =>
  import("../views/PrivacyPolicyCustomer")
);
const Referral = lazy(() => import("../views/Referral"));
const TermsOfService = lazy(() => import("../views/TermsOfService"));
const UseCase = lazy(() => import("../views/UseCase"));
const Marketing = lazy(() => import("../views/Marketing"));
const AboutUs = lazy(() => import("../views/AboutUs"));
const CaseStudy = lazy(() => import("../views/CaseStudy"));
const Connector = lazy(() => import("../views/Connector"));
const ContactUs = lazy(() => import("../views/ContactUs"));
const CancellationPolicy = lazy(() => import("../views/CancellationPolicy"));
const FaqPage = lazy(() => import("../views/FaqPage"));
const ForgetPassword = lazy(() => import("../views/ForgetPassword"));
const IngestionLanding = lazy(() => import("../views/IngestionLanding"));
const StorageView = lazy(() => import("../views/StorageView"));
const BIView = lazy(() => import("../views/BIView"));
const OpenEngine = lazy(() => import("../views/OpenEngine/Landing"));

export const routes = [
  {
    path: "/",
    component: OlakeHome,
    layout: NewLayout,
    isExact: true,
  },
  {
    path: "/onestack-data",
    component: Home,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/dashboard",
    component: Dashboard,
    layout: LayoutDashboard,
    isExact: false,
  },
  {
    path: "/onestack-data/sign-up",
    component: Signup,
    layout: LayoutFull,
    isExact: true,
  },
  {
    path: "/onestack-data/ingestion",
    component: IngestionLanding,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/onestack-data/storage",
    component: StorageView,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/onestack-data/business-intelligence",
    component: BIView,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/onestack-data/login",
    component: Login,
    layout: LayoutFull,
    isExact: true,
  },
  {
    path: "/onestack-data/forgot-password",
    component: ForgetPassword,
    layout: LayoutFull,
    isExact: true,
  },
  {
    path: "/about-us",
    component: AboutUs,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/contact-us",
    component: ContactUs,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/onestack-data/solutions/embedded-analytics",
    component: EmbedAnalytics,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/onestack-data/pricing",
    component: Pricing,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/onestack-data/early-access",
    component: Referral,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/onestack-data/faq",
    component: FaqPage,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/onestack-data/connectors",
    component: Connector,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/onestack-data/case-studies",
    component: CaseStudy,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/onestack-data/use-cases",
    component: UseCase,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/onestack-data/solutions/marketing",
    component: Marketing,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/onestack-data/solutions/product",
    component: Product,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/onestack-data/solutions/revenue-operations",
    component: RevenueOperations,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/onestack-data/solutions/data-analyst",
    component: DataAnalyst,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/onestack-data/solutions/operations",
    component: Operations,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/onestack-data/solutions/finance",
    component: Finance,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/privacy-policy-customer",
    component: PrivacyPolicyCustomer,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/cancellations-refunds",
    component: CancellationPolicy,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/terms-of-use",
    component: TermsOfService,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/onestack-data/openengine",
    component: OpenEngine,
    layout: LayoutDefault,
    isExact: true,
  },
  {
    path: "/olake",
    component: OlakeProduct,
    layout: NewLayout,
    isExact: true,
  },
  {
    path: "/flatbread",
    component: FlatBreadProduct,
    layout: NewLayout,
    isExact: true,
  },
];
