/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { getDashboardData, getUserData } from "../services/ui";
import Loader from "../components/elements/Loader";
import { logout } from "../services/auth";
import HelmetWrapper from "../components/sections/HelmetWrapper";

const drawerWidth = 125;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
      backgroundColor: "#151719",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      backgroundColor: "#000",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flex: 1,
    minHeight: "100vh",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
  },
  menuItem: {
    fontSize: "1rem",
  },
}));

const MyDrawer = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const deployDetails = useSelector((store) => store.ui.deployDetails);
  const { pathname } = useLocation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = async () => {
    await dispatch(logout({ loader: true }));
    history.push("/onestack-data/login");
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div
        className={`${classes.toolbar} logo-img`}
        onClick={() => history.push("/dashboard")}
      >
        <img
          src={require("../assets/images/Logo-datazip.svg")}
          alt="datazip-logo"
        />
      </div>
      <Divider />
      {deployDetails?.deploy_status === "success" && (
        <div className="dashboard-menu">
          <div
            onClick={() => history.push("/dashboard")}
            className={`${pathname === "/dashboard" && "selected"} `}
          >
            Home
          </div>
          <div className="divider" />
          <div
            onClick={() => history.push("/dashboard/payment/details")}
            className={`${
              pathname === "/dashboard/payment/details" && "selected"
            } `}
          >
            Payment
          </div>
          <div className="divider" />
          <div
            onClick={() => history.push("/dashboard/usage")}
            className={`${pathname === "/dashboard/usage" && "selected"} `}
          >
            Usage
          </div>
          <div className="divider" />
        </div>
      )}

      <div className="dashboard-settings">
        <Button
          aria-controls="fade-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <img
            src={require("../assets/images/settings.svg")}
            alt="settings_icon"
          />
        </Button>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleLogout} className={classes.menuItem}>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

const LayoutDashboard = ({ children, window, hideMark }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);

  const prevLocation = useLocation();
  const history = useHistory();
  const isLoggedIn = useSelector((store) => store.auth.loggedIn);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let response = await dispatch(getUserData({ loader: true }));
      if (response === false) {
        setLoading(false);
        history.push(`/onestack-data/login?redirectTo=${prevLocation.pathname}`);
        return;
      }
      await dispatch(getDashboardData({ loader: true }));
      setLoading(false);
    })();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (loading) {
    return <Loader />;
  }

  if (!isLoggedIn) {
    history.push(`/onestack-data/login?redirectTo=${prevLocation.pathname}`);
  }

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <>
      <HelmetWrapper title={"Datazip - Dashboard"} description={""} />
      <main className="site-full">
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <div className="dzip-logo-mobile">
                <img
                  src={require("../assets/images/dzip-logo-white.svg")}
                  alt="dzip logo"
                />
              </div>
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer} aria-label="mailbox folders">
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <MyDrawer />
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                <MyDrawer />
              </Drawer>
            </Hidden>
          </nav>
          <main className={classes.content}>
            {!hideMark && (
              <div className="dashboard-box">
                <img
                  src={require("../assets/images/datazip-logo-black.svg")}
                  alt="d-logo"
                  className="dash-img"
                />
                <div className="dash-tag">all-in-one data stack</div>
                <div className="dash-heading">Build the whole data story</div>
                {/* <div className="dash-sub">
            The only self-serve decision analytics
            platform, built for the modern data stack that you will need. Your
            business teams can ask any question, find insights, and drill down
            the data for actionable insights
          </div> */}
              </div>
            )}

            {children}
          </main>
        </div>
      </main>
    </>
  );
};

export default LayoutDashboard;
