import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

//import './App.css';
import "./assets/scss/style.scss";

import { Provider } from "react-redux";
import ConfigStore from "./redux/createStore/store";
import { setRequestResponseInterceptor } from "./utils/Request";
import { PersistGate } from "redux-persist/integration/react";
import {gsap} from 'gsap'
import {ScrollTrigger } from 'gsap/ScrollTrigger'

const history = createBrowserHistory();
require("dotenv").config();
const { store, persistor } = ConfigStore();

store.dispatch(setRequestResponseInterceptor());

gsap.registerPlugin(ScrollTrigger);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
