import PropTypes from "prop-types";
import React from "react";
// import FooterNav from "./partials/FooterNav";
import FooterSocial from "./partials/FooterSocial";
import Logo from "./partials/Logo";
import { Link } from "react-router-dom";

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

const NewFooter = ({ className, topOuterDivider, topDivider, ...props }) => {
  return (
    <footer {...props} className="footer">
      <div className="container">
        <div className="left">
          <Logo />
          <div className="footer-heading">Composable Lakehouse Platform</div>
          <FooterSocial />
        </div>
        <div className="right">
          <div className="types">
            <div className="type-heading">Resources</div>
            <ul>
              <a href="/blog">
                <li>Blog</li>
              </a>
            </ul>
          </div>
          <div className="types">
            <div className="type-heading">Company</div>
            <ul>
              <Link to="/about-us">
                <li>About us</li>
              </Link>
            </ul>
          </div>
          <div className="types">
            <div className="type-heading">Legal</div>
            <ul>
              <Link to="/terms-of-use">
                <li>Terms of Use</li>
              </Link>
              <Link to="/privacy-policy">
                <li>Privacy (Visitors)</li>
              </Link>
              <Link to="/privacy-policy-customer">
                <li>Privacy (Customers)</li>
              </Link>
              {/* <Link to="/cancellations-refunds">
                <li>Cancellations and Refunds</li>
              </Link> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="text__sub">© 2024 Datazip. All rights reserved</div>
      <div className="company-address">
        Datazip, Inc. 16192 COASTAL HWY LEWES, DE 19958, USA
      </div>
    </footer>
  );
};

NewFooter.propTypes = propTypes;
NewFooter.defaultProps = defaultProps;

export default NewFooter;
