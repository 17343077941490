import React from "react";
import NewHeader from "../components/layout/NewHeader";
import NewFooter from "../components/layout/NewFooter";

const NewLayout = ({ children }) => (
  <>
    {/* removed header animation from here . To be added -- reveal-from-bottom */}
    <NewHeader navPosition="right" className="" />
    <main className="site-content">{children}</main>
    <NewFooter />
  </>
);

export default NewLayout;
