import { LOGOUT, SETAUTHTOKEN } from "../actions/authActions/actionType";


const initialState = {
    loggedIn: false,
    user: null,
};


const authReducer = (state = initialState, action) =>{
    switch(action.type){
        case SETAUTHTOKEN:
            return {
                ...state,
                loggedIn: true,
            }
        case LOGOUT:
            return {
                ...state,
                loggedIn: false,
                token: null,
            }
        case 'SETMYUSER':
            return {
                ...state,
                user: {...action.payload}
            }
        default:
            return state;
    }
}


export default authReducer;