import {
  SETLOADING,
  SETUSER,
  UPDATEAPITOKENS,
  UPDATEDETAILS,
  UPDATEUSER,
} from "./actionType";

export const setLoader = (state) => async (dispatch, getState) => {
  let loaderState = getState().ui.isLoading;

  if (loaderState !== state) {
    dispatch({
      type: SETLOADING,
    });
  }
};

export const addApiToken =
  (id, type = "local", source) =>
  (dispatch, getState) => {
    let tokens = getState().ui.activeApiTokens;
    tokens = [...tokens, { id, type, source }];

    dispatch({
      type: UPDATEAPITOKENS,
      payload: tokens,
    });
  };

export const removeApiToken =
  (id, type = "local", source) =>
  (dispatch, getState) => {
    let tokens = getState().ui.activeApiTokens;
    tokens = tokens.filter((token) => token.id !== id);

    dispatch({
      type: UPDATEAPITOKENS,
      payload: tokens,
    });
  };

export const updateDetails = (details) => (dispatch, getState) => {
  dispatch({
    type: UPDATEDETAILS,
    payload: details,
  });
};

export const setUser = (user) => (dispatch, getState) => {
  dispatch({
    type: SETUSER,
    payload: user,
  });
};

export const updateUser = (details) => (dispatch, getState) => {
  dispatch({
    type: UPDATEUSER,
    payload: details,
  });
};
