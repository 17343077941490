import {
  legacy_createStore as createStore,
  compose,
  applyMiddleware,
} from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers/rootReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const middleware = [thunk];

const initialState = {};

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  let store = createStore(
    persistedReducer,
    initialState,
    compose(
      applyMiddleware(...middleware),
      window._REDUX_DEVTOOLS_EXTENSION_
        ? window._REDUX_DEVTOOLS_EXTENSION_()
        : (f) => f
    )
  );
  let persistor = persistStore(store);
  return { store, persistor };
};
