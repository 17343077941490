import { combineReducers } from "redux";
import { LOGOUT } from "../actions/authActions/actionType";
import authReducer from "./authReducer";
import uiReducer from "./uiReducer";
import paymentReducer from "./paymentReducer";

const appReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  payment: paymentReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    localStorage.removeItem("persist:root");
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
