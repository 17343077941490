import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: "transparent",
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  heading: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "140%",
    color: "#fff",
    fontFamily: "Poppins",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
  answer: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "180%",
    color: "rgba(255, 255, 255, 0.56)",
    fontFamily: "Poppins",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
}));

const Faq = ({ data, showHeading }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        {showHeading && (
          <div className="text__heading ta-c" style={{ marginBottom: "50px" }}>
            Frequently Asked Questions
          </div>
        )}

        {data?.map((item, index) => {
          return (
            <Accordion
              key={index}
              className={classes.box}
              defaultExpanded={index === 0 ? true : false}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {item?.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box className={classes.answer}>{item?.answer}</Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </>
  );
};

export default Faq;
