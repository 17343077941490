import axios from "axios";
import {
  addApiToken,
  removeApiToken,
} from "../redux/actions/uiActions/actions";
import { logout } from "../services/auth";

const environment = process.env.NODE_ENV;

const url =
  environment === "development"
    ? process.env.REACT_APP_BACKEND_DEV
    : process.env.REACT_APP_URL;

const Request = axios.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export const setRequestResponseInterceptor = () => (dispatch, getState) => {
  Request.interceptors.request.use(
    (request) => {
      dispatch(handleLoaderRequest(request));
      return request;
    },
    (error) => {
      return error;
    }
  );

  Request.interceptors.response.use(
    (response) => {
      dispatch(handleLoaderResponse(response));
      if (response.status === 401 && getState().auth.loggedIn === true) {
        dispatch(handleAutoLogout(response));
      }
      return response;
    },
    (error) => {
      dispatch(handleLoaderResponse(error?.response));
      if (
        error?.response?.status === 401 &&
        getState().auth.loggedIn === true
      ) {
        // logout here
        dispatch(handleAutoLogout(error?.response));
      }
      return error.response;
    }
  );
};

// const attachTokenInHeader = (Request, token) => {
//   token && (Request.headers.Authorization = "Bearer " + token);
// };

const handleLoaderRequest = (Request) => (dispatch) => {
  if (Request.loader) {
    let source = axios.CancelToken.source();
    let id = Date.now();
    let type = Request.type ?? "local";
    Request.cancelToken = source.token;
    Request.apiId = id;
    dispatch(addApiToken(id, type, source));
  }
};

const handleLoaderResponse = (Response) => (dispatch) => {
  if (Response?.config?.loader) {
    let id = Response.config.apiId;
    let type = Response.config.type ?? "local";
    dispatch(removeApiToken(id, type));
  }
};

const handleAutoLogout = (Response) => async (dispatch) => {
  if (Response?.status === 401) {
    await dispatch(logout());
    // logout here
  }
};

export default Request;
