export const mainMetaData = {
  title: "Making Data Engineers 10x More Productive",
  description:
    "Ingest, store, organize, and query all your data at one place while we take care of the infra scale, enabling data quality and data access.",
  image: require("../assets/images/onestack-meta.png"),
};

export const mainOlakeData = {
  title: "Composable Lakehouse Platform",
  description:
    "Leverage open data formats to decouple storage, metadata, and compute for best-in-class analytics.",
  image: require("../assets/images/olake-meta.png"),
};

export const olakeProductData = {
  title: "Fastest way to replicate MongoDB/JSON data in Apache Iceberg",
  description:
    "Achieve fast data replication for MongoDB with near real-time transfer to data lakehouse (in Iceberg format).",
  image: require("../assets/images/olake-product-meta.png"),
};

export const aboutMetaData = {
  title: "About Datazip - Transforming Data Engineering",
  description:
    "Learn about Datazip's mission: providing analytics teams with a cutting-edge, no-code data engineering solution.",
};

export const connectorMetaData = {
  title: "Datazip Connectors - Seamless Data Integration",
  description:
    "Explore Datazip's connectors: effortlessly link data sources for streamlined analytics and engineering.",
};

export const blogMetaData = {
  title: "Datazip Blog - Insights on Data",
  description:
    "Stay informed with Datazip's blog, featuring expert advice, trends, and updates in data.",
};

export const pricingMetaData = {
  title: "Datazip Pricing - Flexible Plans for Teams",
  description:
    "Choose from Datazip's cost-effective plans, tailored to suit analytics teams of all sizes and needs.",
};

export const caseStudyMetaData = {
  title: "Datazip Case Studies - Proven Success Stories",
  description:
    "Explore real-life applications of Datazip, showcasing how analytics teams revolutionize data engineering.",
};

export const loginMetaData = {
  title: "Datazip Login - Access Your Platform",
  description:
    "Log in to Datazip and experience seamless no-code data engineering designed for analytics teams.",
};

export const communityMetaData = {
  title: "Datazip Community - Collaborate & Learn",
  description:
    "Join Datazip's vibrant community, where analytics teams share knowledge, ideas, and best practices.",
};

export const productMetaData = {
  title: "Datazip Solutions - Advanced Custom Product Analytics",
  description:
    "Unlock powerful, customizable product analytics with Datazip, a no-code data engineering platform for teams.",
};

export const marketingMetaData = {
  title: "Datazip Solutions - Advanced Custom Marketing Analytics",
  description:
    "Enhance marketing analytics with Datazip, offering powerful, tailored insights through a no-code data engineering platform.",
};

export const revOpsMetaData = {
  title: "Datazip Solutions - RevOps Analytics",
  description:
    "Optimize RevOps with Datazip's custom analytics, powered by our no-code data engineering platform for teams.",
};

export const analystMetaData = {
  title: "Datazip Solutions - Unlocking powerful analytics for analysts",
  description:
    "Empower data analysts with Datazip's data platform, driven by a no-code data engineering platform for teams.",
};

export const operationsMetaData = {
  title: "Datazip Solutions - Advanced Custom Operations Analytics",
  description:
    "Boost operations with custom analytics using Datazip, a scalable, no-code data engineering platform for teams.",
};

export const financeMetaData = {
  title: "Datazip Solutions - Advanced Custom Finance Analytics",
  description:
    "Enhance financial analytics with Datazip's data platform, powered by a no-code data engineering platform for teams.",
};

export const analyticsMetaData = {
  title: "Datazip Solutions - Embedded Analytics, Built to Scale",
  description:
    "Experience easy setup and scalable Embedded Analytics with Datazip, a no-code data engineering platform for teams.",
};

export const signupMetaData = {
  title: "Sign Up for Datazip - Transform Data Engineering",
  description:
    "Join Datazip today and unlock a scalable, no-code data engineering platform designed for analytics teams.",
};

export const faqMetaData = {
  title: "Datazip FAQs - Get Your Questions Answered",
  description:
    "Find answers to common queries about Datazip, the scalable, no-code data engineering platform for analytics teams.",
};

export const contactMetaData = {
  title: "Contact Datazip - Reach Out Today",
  description:
    "Get in touch with our Datazip team, experts in scalable, no-code data engineering platforms for analytics professionals.",
};

export const termsMetaData = {
  title: "Datazip Terms of Use - User Guidelines",
  description:
    "Review Datazip's Terms of Use, ensuring responsible and secure use of our no-code data engineering platform.",
};

export const privacyCMetaData = {
  title: "Datazip Customer Privacy - Protecting Data",
  description:
    "Learn how Datazip safeguards customer information, prioritizing privacy.",
};

export const privacyVMetaData = {
  title: "Datazip Visitor Privacy - Ensuring Security",
  description: "Discover Datazip's commitment to visitor privacy.",
};

export const cancellationMetaData = {
  title: "Datazip Cancellation & Refunds",
  description:
    "Review Datazip's cancellation and refund policies, ensuring user satisfaction.",
};

export const openEngineMeta = {
  title: "OpenEngine by Datazip - Get On-Demand Virtual Data Warehouses",
  description:
    "Automatically spins up compute resources in 30 secs, get uninterrupted, cost-effective data querying solutions built on the capabilities of ClickHouse.",
};
