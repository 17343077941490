import React, { useEffect, useRef } from "react";
import Faq from "../../components/sections/Faq";
import FlatbreadBlogList from "./Blog";
import ScrollReveal from "../../utils/ScrollReveal";
import { useHistory } from "react-router-dom";
import HelmetWrapper from "../../components/sections/HelmetWrapper";
import { olakeProductData } from "../../seo/metaDeta";
import FlatBreadFeatures from "./Feature";
import FlatbreadFunctionality from "./Functionality";

const FlatbreadFaqs = [
  {
    question:
      "How does this tool compare to other data integration and transformation solutions?",
    answer:
      "This tool specializes in handling JSON data, offering a more streamlined and efficient approach compared to generic solutions. Built to manage polymorphic data, arrays and schema changes.",
  },
  {
    question: "Can this tool handle large datasets?",
    answer:
      "Yes, the tool is designed to efficiently process large volumes of JSON data.",
  },
  {
    question: "What data platforms and tools does FlatBread integrate with?",
    answer:
      "Yes, it integrates with most of the standard data warehouse and big data platforms like Postgres, Snowflake, Databricks, Redshift and BigQuery",
  },
  {
    question: "How it handles nested JSON and Array structures?",
    answer:
      "FlatBread offers customised manner to define the level of flattening and for arrays it creates nested tables with separate table for each column with array data type.",
  },
  {
    question:
      "For new data with changed data types for keys, how do you handle it?",
    answer:
      "We have multiple ways to deal with this, one of the ways is to append data_type in column name which will make it unique, another way is to alert the user on unaccommodating new rows/jsons and put them into a dead-letter table, where user can process them later based on intelligent AI suggestions.",
  },
];

const FlatBreadProduct = () => {
  const childRef = useRef();
  const formRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    childRef.current.init();
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    script.async = true;
    script.onload = () => {
      window.hbspt.forms.create({
        target: "#olake-product-form",
        portalId: "21798546",
        formId: "58449b7b-4aba-4def-9991-33b364ac2294",
      });
    };
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (window.location.hash === "#olake-form-product") {
      setTimeout(() => {
        window.scrollTo(0, formRef.current.offsetTop);
      }, [0]);
      history.replace({
        pathname: window.location.pathname,
        search: window.location.search,
      });
    }
  }, [history, history.location.hash]);

  return (
    <>
      <HelmetWrapper {...olakeProductData} />
      <ScrollReveal
        ref={childRef}
        children={() => (
          <div className="flatbread-product-container">
            <div className="flatbread-product-brand reveal-from-top">
              <img
                src={require("../../assets/images/flatbread-logo.svg")}
                alt="flatbread logo"
              />
            </div>
            <div className="flatbread-product-heading reveal-from-bottom">
              {/* <span className="flatbread-product-heading-type1">Fastest</span>{" "} */}
              <span className="flatbread-product-heading-type2">
                Flatten complex JSON into SQL
              </span>{" "}
              {/* <span className="flatbread-product-heading-type3">MongoDB/JSON</span>{" "} */}
              <br /> <br />
              <span className="flatbread-product-heading-type2">
                ready tables in minutes
              </span>
            </div>
            <img
              src={require("../../assets/images/flatbread-home.svg")}
              alt="flatbread-architecture"
              className="flatbread-product-architecture reveal-from-bottom"
            />
            <a href="#flatbread-form-product">
              <button className="flatbread-product-waitlist-button reveal-from-bottom">
                Join Waitlist
              </button>
            </a>
            <FlatBreadFeatures />
            <FlatbreadFunctionality />
            <div
              id="flatbread-form-product"
              className="flatbread-product-form-container"
              ref={formRef}
            >
              <div className="flatbread-product-form-container-left">
                <div className="flatbread-product-form-brand">
                  <img
                    src={require("../../assets/images/flatbread-logo.svg")}
                    alt="flatbread logo vector"
                  />
                </div>
                <div className="flatbread-product-form-heading">
                  Interested?
                  <br /> Get Early Access.
                </div>
              </div>
              <div className="flatbread-product-form-container-right">
                <div id="olake-product-form"></div>
              </div>
            </div>
            <FlatbreadBlogList />
            <div className="olake-product-faq">
              <Faq data={FlatbreadFaqs} showHeading={true} />
            </div>
          </div>
        )}
      />
    </>
  );
};

export default FlatBreadProduct;
