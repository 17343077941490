import classNames from "classnames";
import React from "react";
import { handleGaCustomEvent } from "../../../utils/functions";

const FooterSocial = ({ className, ...props }) => {
  const classes = classNames("footer-social", className);

  return (
    <div {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <a
            href="https://www.linkedin.com/company/datazipio"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              handleGaCustomEvent("external clicks", "LinkedIn click");
            }}
          >
            <img
              src={require("../../../assets/images/linkedin.svg")}
              alt="linkedin"
            />
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/channel/UCENMiZcDKVBo55uxtB2JAwg"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              handleGaCustomEvent("external clicks", "Youtube click");
            }}
          >
            <img
              src={require("../../../assets/images/youtube.svg")}
              alt="youtube"
            />
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/people/Datazip/100086086352895"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              handleGaCustomEvent("external clicks", "Facebook click");
            }}
          >
            <img
              src={require("../../../assets/images/facebook.svg")}
              alt="facebook"
            />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/datazipio"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              handleGaCustomEvent("external clicks", "Twitter click");
            }}
          >
            <img
              src={require("../../../assets/images/twitter.svg")}
              alt="twitter"
            />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterSocial;
