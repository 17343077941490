import React from "react";
import { useLocation } from "react-router-dom";

let myScopes = [
  "/dashboard",
  "/onestack-data/login",
  "/onestack-data/sign-up",
  "/dashboard/payment/details",
  "/dashboard/deploy",
  "/dashboard/usage",
  "/dashboard/payment/details/add_card",
  "/dashboard/payment/details/add_address",
  "/dashboard/payment/details/choose_plan",
  "/onestack-data/forgot-password",
];

const Loader = () => {
  const { pathname } = useLocation();

  return (
    <div className={`loader ${myScopes.includes(pathname) && "white"}`}>
      <img src={require("../../assets/images/256x256.gif")} alt="loader" />
    </div>
  );
};

export default Loader;
